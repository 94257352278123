import { Box, Button, Container, Flex, Menu } from '@lego/klik-ui';
import { ArrowDownBold } from '@lego/klik-ui-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { default as i18n, default as i18next, languages } from '../utils/i18n';

export const LandingPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();


  const handleShipout = () => {
    navigate('/ship-out', { replace: true });
  }

  const handleBookingIn = () => {
    navigate('/booking-in', { replace: true });
  }

  return (
    <Flex direction="column" h="100vh">
      <Flex flex="2" align="center" justify="center" fontSize="5xl">
        {translate('login_page.welcome')}
      </Flex>
      <Flex flex="7" align="center" justify="center" bg="primary">
        <Container w="400px">
          <Button size="lg" mr={3} onClick={handleBookingIn}>
            {translate('landing_page.booking_in')}
          </Button>
          <Button size="lg" onClick={handleShipout}>
            {translate('landing_page.ship_out')}
          </Button>
        </Container>
      </Flex>
      <Flex flex="3">
        <Flex flex="3" align="center" justify="left">
          <Box ml="8" color="black">
            <Box>{translate('shared.get_help')}</Box>
          </Box>
        </Flex>
        <Flex flex="3" align="center" justify="right" margin={10}>
          <Menu>
            <Menu.Button as={Button} rightIcon={<ArrowDownBold />} borderWidth="2px">
              {i18next.language}
            </Menu.Button>
            <Menu.List border={0} borderRadius={0} background="#E8F2FA">
              {languages.map((lang) => (
                <Menu.Item
                  key={lang.code}
                  isDisabled={lang.code === i18next.language}
                  onClick={() => void i18n.changeLanguage(lang.code)}
                >
                  {lang.code} - {lang.name}
                </Menu.Item>
              ))}
            </Menu.List>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};
