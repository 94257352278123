import { User } from "@digi-waste/generated-models";
import { Box, CircularProgress, Table, Text, VerticalStack } from "@lego/klik-ui";
import { flexRender, Table as TableInstance } from "@tanstack/react-table";
import React from "react";
import { useTranslation } from "react-i18next";

interface IUserTableComponentProps {
    table: TableInstance<User>;
    isLoading: boolean;
}

export const UserTableComponent: React.FC<IUserTableComponentProps> = ({
    table,
    isLoading
}) => {
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <Box alignItems="center" display="flex" h={300} justifyContent="center" w="100">
                <CircularProgress isIndeterminate={true} value={3} />
            </Box>
        )
    }

    if (!table.getRowModel().rows.length) {
        return (
            <Box alignItems="center" display="flex" h={400} justifyContent="center">
                <VerticalStack spacing={3}>
                    <Text fontSize="2xl" fontWeight="medium">
                        {t('user_table.no_users')}
                    </Text>
                </VerticalStack>
            </Box>
        );
    }

    return (
        <Table style={{ tableLayout: "auto" }}>
            <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => {
                    return (
                        <Table.Row key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Table.ColumnHeader key={header.id} alignItems="center" height="100%">
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </Table.ColumnHeader>
                                )
                            })}
                        </Table.Row>
                    )
                })}
            </Table.Head>
            <Table.Body>
                {table.getRowModel().rows.map((row) => {
                    return (
                        <Table.Row key={row.id} {...row.original.isActive ? {} : { bgColor: "slate.30" }}>
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <Table.Cell key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </Table.Cell>
                                )
                            })}
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}