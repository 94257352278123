import { Box, Button, Grid, Input, IStackProps, Stack } from '@lego/klik-ui';
import { Delete, LogIn } from '@lego/klik-ui-icons';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18n from '../../../utils/i18n';
import { IRegistrationForm } from '../../../utils/types/custom';
import { CalculatorButton } from '../components/CalculatorButton';

interface IGenericStepProps extends IStackProps {
  goToNextStep: () => void;
  isLoading: boolean;
}

// Step where user fill in weight number
export const WeightStep: React.FC<IGenericStepProps> = ({
  goToNextStep,
  isLoading,
  ...stackProps
}) => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<IRegistrationForm>();

  // For some reason the default value is not set everytime so we do ?? '0' to prevent errors
  const weight = watch('weight', '0') ?? '0';
  // We will show weight in local format to make sure decimal separator is correct
  const localizedWeight = (+weight).toLocaleString(i18n.language);
  // We will get localized decimal separator for button
  const decimalSeparator = (1.1).toLocaleString(i18n.language).substring(1, 2);

  // Update current weight based on pushed button and update it in form
  const updateWeight = useCallback(
    (value: string) => {
      let newWeight = weight;
      switch (value) {
        // Clear all data = reset to 0
        case 'clear':
          newWeight = '0';
          break;
        // Remove last digit/decimal separator
        case 'delete':
          if (weight.length > 0) {
            newWeight = weight.substring(0, weight.length - 1);
          }
          break;
        // Toggle decimal separator
        case '.':
          // If decimal separator is not present, add it
          if (weight.indexOf('.') === -1) {
            newWeight += '.';
          }
          // If decimal separator is present, remove it
          else if (weight[weight.length - 1] === '.') {
            newWeight = weight.substring(0, weight.length - 1);
          }
          break;

        default:
          // If weight is 0, replace it with value
          if (weight === '0') {
            newWeight = value;
          }
          // If weight is not 0, add value to it
          else {
            newWeight += value;
          }
          break;
      }
      // Update weight in form
      setValue('weight', newWeight || '0');
    },
    [weight, setValue]
  );

  return (
    <Stack spacing={6} {...stackProps} padding={5} width="100vw" alignItems="stretch">
      <Grid
        w={{
          md: '400px',
          sm: '300px',
          base: '260px',
        }}
        gap="4"
        templateRows="20px auto-fit 1fr 1fr 1fr 1fr"
        templateColumns="repeat(4, 1fr)"
        alignSelf="center"
      >
        {/* Row 1 */}
        <Grid.Item colSpan={4}>
          <Box
            fontWeight="bold"
            fontSize={{
              md: 'xl',
              sm: 'lg',
              base: 'lg',
            }}
          >
            {t('regi_page.enter_weight')}
          </Box>
        </Grid.Item>

        {/* Row 2 */}
        <Grid.Item colSpan={4}>
          <Input
            fontSize={{
              md: '6xl',
              sm: '5xl',
              base: '4xl',
            }}
            borderWidth="1px"
            borderRadius="md"
            size="lg"
            textAlign="end"
            color="information.600"
            value={localizedWeight}
            isReadOnly
            onChange={() => {}}
          />
        </Grid.Item>

        {/* Row 3 */}
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('1')}>
          1
        </CalculatorButton>
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('2')}>
          2
        </CalculatorButton>
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('3')}>
          3
        </CalculatorButton>
        <CalculatorButton
          colorScheme="slate"
          variant="var300"
          onClick={() => updateWeight('delete')}
        >
          <Delete />
        </CalculatorButton>

        {/* Row 4 */}
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('4')}>
          4
        </CalculatorButton>
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('5')}>
          5
        </CalculatorButton>
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('6')}>
          6
        </CalculatorButton>
        <Button
          colorScheme="slate"
          variant="var300"
          w="100%"
          h="100%"
          py={{
            md: 6,
            sm: 4,
            base: 2,
          }}
          onClick={() => updateWeight('clear')}
        >
          {t('regi_page.clear_weight')}
        </Button>

        {/* Row 5 */}
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('7')}>
          7
        </CalculatorButton>
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('8')}>
          8
        </CalculatorButton>
        <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('9')}>
          9
        </CalculatorButton>
        <Grid.Item rowSpan={2} w="100%" h="100%">
          <CalculatorButton
            bg="light-blue.600"
            onClick={goToNextStep}
            disabled={+weight.replaceAll(',', '.') === 0}
            isLoading={isLoading}
          >
            <LogIn />
          </CalculatorButton>
        </Grid.Item>

        {/* Row 6 */}
        <Grid.Item colSpan={2} w="100%" h="100%">
          <CalculatorButton colorScheme="slate" variant="var200" onClick={() => updateWeight('0')}>
            0
          </CalculatorButton>
        </Grid.Item>
        <CalculatorButton
          colorScheme="slate"
          bg={weight[weight.length - 1] === '.' ? 'light-blue.300' : undefined}
          variant="var200"
          onClick={() => updateWeight('.')}
          disabled={weight.indexOf('.') !== -1 && weight[weight.length - 1] !== '.'}
        >
          {decimalSeparator}
        </CalculatorButton>
      </Grid>
    </Stack>
  );
};
