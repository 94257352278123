import { Flex, IconButton, Stack } from '@lego/klik-ui';
import { ArrowLeftBold, ArrowRightBold } from '@lego/klik-ui-icons';
import { useTranslation } from 'react-i18next';
import { CancelRegistration } from './CancelRegistration';

interface IProps {
  canGoForward: boolean;
  canGoBack: boolean;
  goBack: () => void;
  goForward: () => void;
  cancelRegistration: () => void;
}

// Footer with previous, next, cancel buttons
export const FooterNavigation: React.FC<IProps> = ({
  canGoForward,
  canGoBack,
  goBack,
  goForward,
  cancelRegistration,
}) => {
  const { t } = useTranslation();

  return (
    <Flex width="100vw" justify="center">
      <Stack alignItems="center" direction="row" spacing="8" color="black">
        <IconButton
          aria-label={t('regi_stepper.move_back')}
          icon={<ArrowLeftBold />}
          isDisabled={!canGoBack}
          onClick={goBack}
          colorScheme="light-blue"
          variant="var100"
        />

        <CancelRegistration cancelRegistration={cancelRegistration} />

        <IconButton
          aria-label={t('regi_stepper.move_next')}
          icon={<ArrowRightBold />}
          isDisabled={!canGoForward}
          onClick={goForward}
          colorScheme="light-blue"
          variant="var100"
        />
      </Stack>
    </Flex>
  );
};
