import { Area, Container, Registration, WasteType } from '@digi-waste/generated-models';
import { Box, Flex, Grid, Image } from '@lego/klik-ui';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessIcon from '../utils/assets/success.svg';
import { hazardousKey, nonHazardousKey } from '../utils/consts';

// Page indicating registration succeeded
export const RegistrationSuccessPage: React.FC = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const navigate = useNavigate();

  // We expect registration object including id be passed in state
  const report = state as Registration;

  const qc = useQueryClient();
  const containers = qc.getQueryData<Container[]>(['containers']);
  const areas = qc.getQueryData<Area[]>(['areas']);
  const wasteTypes = qc.getQueryData<WasteType[]>(['wasteTypes', report.areaId]);

  const area = areas?.find((x) => x.id === report.areaId);
  const container = containers?.find((x) => x.id === report.containerId);
  const wasteType = wasteTypes?.find((x) => x.id === report.wasteTypeId);
  const category = wasteType?.isHazardous ? t(hazardousKey) : t(nonHazardousKey);

  const entryDate = t('intlDateTime', {
    val: new Date(),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
    },
    interpolation: { escapeValue: false },
  });

  // If we are starting new entry we redirect without state
  const handleStartNewEntry = () => {
    navigate('/', { replace: true });
  };

  // If we want to adjust entry we redirect with report in state
  const handleEditEntry = () => {
    navigate('/', { state: report, replace: true });
  };

  return (
    <Flex direction="column" h="100vh">
      <Flex flex="1" bg="primary"></Flex>
      <Flex flex="8" align="center" justify="center" direction="column">
        <Box
          mb={{
            md: 12,
            sm: 8,
            base: 4,
          }}
          fontWeight="bold"
          fontSize={{
            md: '6xl',
            sm: '4xl',
            base: '2xl',
          }}
          color="success.400"
          textAlign="center"
        >
          {t('rep_succcess_page.report_successfully')}
        </Box>
        <Flex
          align="center"
          justify="center"
          direction="column"
          cursor="pointer"
          onClick={handleStartNewEntry}
        >
          <Image ignoreFallback={true} src={SuccessIcon} />
          <Box m="4" fontSize="xl" fontWeight="medium">
            {t('rep_succcess_page.add_new_entry')}
          </Box>
        </Flex>
      </Flex>
      <Flex flex="3" bg="primary" align="center" justify="left" p="6">
        <Box bg="light-blue.50" borderRadius="md" p="4" maxW="sm">
          <Box fontWeight="bold">{t('rep_succcess_page.latest_entry')}:</Box>
          <Grid columnGap="4" templateColumns="2fr 3fr" mt="4">
            <Box>{t('rep_succcess_page.date_label')}:</Box>
            <Box>{entryDate}</Box>
            <Box>{t('regi_stepper.area')}:</Box>
            <Box isTruncated>{area?.name ? t(`db:areas.${area.name}`) : report.areaId}</Box>
            <Box>{t('regi_stepper.category')}:</Box>
            <Box isTruncated>{category}</Box>
            <Box>{t('regi_stepper.wastetype')}:</Box>
            <Box isTruncated>
              {wasteType?.name ? t(`db:waste_types.${wasteType.name}`) : report.wasteTypeId}
            </Box>
            <Box>{t('regi_stepper.container')}:</Box>
            <Box isTruncated>
              {container?.name ? t(`db:containers.${container.name}`) : report.containerId}
            </Box>
            <Box>{t('regi_stepper.weight')}:</Box>
            <Box>{`${t('intlNumber', { val: report.weight })} kg`}</Box>
          </Grid>
          <Box mt="4" cursor="pointer" fontSize="sm" fontWeight="bold" onClick={handleEditEntry}>
            {t('rep_succcess_page.edit_entry')}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};
