import { WasteType } from '@digi-waste/generated-models';
import { Box, Divider, Grid, IconButton, IStackProps, Stack } from '@lego/klik-ui';
import { Minus, Plus } from '@lego/klik-ui-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { hazardousKey } from '../../../utils/consts';
import { IRegistrationForm } from '../../../utils/types/custom';
import { Card } from '../components/Card';
import { ImageCard } from '../components/ImageCard';

interface IGenericStepProps extends IStackProps {
  goToNextStep: () => void;
  wasteTypes?: WasteType[];
}

// Waste type step showing popular types on top, all other types below. Uncommon types are hidden behind + button.
export const WasteTypeStep: React.FC<IGenericStepProps> = ({
  goToNextStep,
  wasteTypes = [],
  ...stackProps
}) => {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext<IRegistrationForm>();

  const [showAll, setShowAll] = useState(false);

  const categoryName = getValues('categoryName');
  const selected = getValues('wasteTypeId');

  const types = useMemo(() => {
    // If + button is toggled we are showing all waste types (including uncommon + all categories)
    if (showAll) {
      return wasteTypes;
    }
    // If + button is not toggled we show only common waste types of selected category
    else {
      let t = wasteTypes.filter(
        (x) => x.isCommon && x.isHazardous === (categoryName === hazardousKey)
      );

      // If there are not type of category in common, show all of category
      if (t.length === 0) {
        t = wasteTypes.filter((x) => x.isHazardous === (categoryName === hazardousKey));
      }

      return t;
    }
  }, [wasteTypes, showAll, categoryName]);

  // When type card is clicked, set the wasteTypeId to the value of the card and move to the next step
  const onClick = useCallback(
    (value: WasteType) => {
      setValue('wasteTypeId', value.id);
      setValue('wasteTypeName', value.name);
      goToNextStep();
    },
    [setValue, goToNextStep]
  );

  return (
    <Stack spacing={6} {...stackProps} padding={10} width="100vw">
      <Box fontWeight="bold" fontSize="xl">
        {t('regi_page.popular_items', { name: t('regi_stepper.wastetypes') })}
      </Box>
      <Grid
        gap="6"
        templateColumns={{
          lg: 'repeat(4, 1fr)',
          md: 'repeat(3, 1fr)',
          base: 'repeat(2, 1fr)',
        }}
        mt="2"
      >
        {types
          .filter((x) => x.isPopular)
          .map((wasteType) => (
            <Card
              key={wasteType.id}
              text={t(`db:waste_types.${wasteType.name}`)}
              subText={''}
              image={'./images/wastetype/' + wasteType.image}
              onClick={() => onClick(wasteType)}
              bg="light-blue.50"
              focus={wasteType.id === selected}
            />
          ))}
      </Grid>

      <Divider my="8" />

      <Box fontWeight="bold" fontSize="xl">
        {t('regi_page.all_items', { name: t('regi_stepper.wastetypes') })}
      </Box>
      <Grid
        gap="6"
        templateColumns={{
          lg: 'repeat(6, 1fr)',
          md: 'repeat(4, 1fr)',
          base: 'repeat(2, 1fr)',
        }}
        mt="2"
        pb="12"
      >
        {types
          .filter((x) => !x.isPopular)
          .map((wasteType) => (
            <ImageCard
              key={wasteType.id}
              text={t(`db:waste_types.${wasteType.name}`)}
              image={'./images/wastetype/' + wasteType.image}
              focus={wasteType.id === selected}
              onClick={() => onClick(wasteType)}
            />
          ))}

        <IconButton
          justifySelf="center"
          alignSelf="center"
          aria-label={showAll ? t('regi_page.show_more') : t('regi_page.show_less')}
          size="lg"
          icon={showAll ? <Minus /> : <Plus />}
          onClick={() => setShowAll(!showAll)}
        ></IconButton>
      </Grid>
    </Stack>
  );
};
