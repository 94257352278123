import { unknownAreaId } from '@digi-waste/common';
import { Area } from '@digi-waste/generated-models';
import { Box, Divider, Grid, IconButton, IStackProps, Stack } from '@lego/klik-ui';
import { Minus, Plus } from '@lego/klik-ui-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IRegistrationForm } from '../../../utils/types/custom';
import { Card } from '../components/Card';
import { SimpleCard } from '../components/SimpleCard';

interface IGenericStepProps extends IStackProps {
  goToNextStep: () => void;
  areas: Area[];
}

// Area step showing popular areas on top and all other areas below
export const AreaStep: React.FC<IGenericStepProps> = ({ goToNextStep, areas, ...stackProps }) => {
  const { t } = useTranslation(['app', 'db']);

  const { getValues, setValue } = useFormContext<IRegistrationForm>();

  const [showAll, setShowAll] = useState(false);

  const selectedArea = getValues('areaId');

  // When area card is clicked, set the areaId to the value of the card and move to the next step
  const onClick = useCallback(
    (area: Area) => {
      setValue('areaId', area.id);
      setValue('areaName', area.name);
      goToNextStep();
    },
    [setValue, goToNextStep]
  );

  const filteredAreas = useMemo(() => {
    return showAll ? areas : areas.filter((x) => x.isCommon);
  }, [areas, showAll]);

  return (
    <Stack spacing={6} {...stackProps} padding={10} width="100vw">
      <Box fontWeight="bold" fontSize="xl">
        {t('regi_page.popular_items', { name: t('regi_stepper.areas') })}
      </Box>
      <Grid
        gap="6"
        templateColumns={{
          lg: 'repeat(5, 1fr)',
          md: 'repeat(3, 1fr)',
          base: 'repeat(2, 1fr)',
        }}
        mt="2"
      >
        {filteredAreas
          .filter((x) => x.isPopular)
          .map((area: Area) => (
            <Card
              key={area.id}
              text={t(`db:areas.${area.name}`)}
              subText={area.desc ?? ''}
              focus={area.id === selectedArea}
              bg={area.id === unknownAreaId ? 'copper.50' : ''}
              onClick={() => onClick(area)}
            />
          ))}
      </Grid>

      <Divider my="8" />

      <Box fontWeight="bold" fontSize="xl">
        {t('regi_page.all_items', { name: t('regi_stepper.areas') })}
      </Box>
      <Grid
        gap="6"
        templateColumns={{
          lg: 'repeat(4, 1fr)',
          md: 'repeat(3, 1fr)',
          base: 'repeat(2, 1fr)',
        }}
        mt="2"
        pb="12"
      >
        {filteredAreas
          .filter((x) => !x.isPopular)
          .map((area: Area) => (
            <SimpleCard
              key={area.id}
              text={t(`db:areas.${area.name}`)}
              focus={area.id === selectedArea}
              onClick={() => onClick(area)}
            />
          ))}
      </Grid>
      <IconButton
        justifySelf="center"
        alignSelf="center"
        aria-label={showAll ? t('regi_page.show_more') : t('regi_page.show_less')}
        size="lg"
        icon={showAll ? <Minus /> : <Plus />}
        onClick={() => setShowAll(!showAll)}
      />
    </Stack>
  );
};
