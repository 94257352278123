const baseStyleLink = {
  '&[data-state="incomplete"]': {
    backgroundColor: 'transparent',
    borderColor: 'light-blue.100',
    color: 'light-blue.100',
  },
  '&[data-state="active"]': {
    backgroundColor: 'transparent',
    borderColor: 'warning.400',
    color: 'warning.400',
  },
  '&[data-state="complete"]': {
    backgroundColor: `white`,
    borderColor: `white`,
    color: '#108A06',
    '&:not([aria-readonly=true])': {
      _hover: {
        backgroundColor: `slate.50`,
        borderColor: `slate.50`,
      },
    },
  },
};

const baseStyleGraph = {
  '&[data-state="complete"]': {
    _before: {
      backgroundColor: `white`,
    },
    _after: {
      backgroundColor: `white`,
    },
  },
  '&[data-state="incomplete"]': {
    _before: {
      backgroundColor: 'white',
    },
    _after: {
      backgroundColor: 'light-blue.100',
    },
  },
  '&[data-state="active"]': {
    _before: {
      backgroundColor: `white`,
    },
    _after: {
      backgroundColor: 'warning.400',
    },
  },
};

export const stepper = {
  baseStyle: {
    link: baseStyleLink,
    graph: baseStyleGraph,
  },
};
