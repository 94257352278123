import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/auth-provider';
import { Log } from '../utils/log';

// Guard that ensures that user is logged in before allowing access to protected pages
export const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  Log.info(auth.userId);
  // If authentication provider was loaded, but we do not have configuration. User is not logged in so we will redirect to login page.
  if (auth.hasLoaded && !auth.userId) {
    return <Navigate replace={true} state={{ from: location }} to="/login" />;
  }

  return children;
};
