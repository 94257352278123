import { Box } from '@lego/klik-ui';

interface SimpleCardProps {
  text: string;
  focus?: boolean;
  onClick?: React.MouseEventHandler;
}

// Styled component used in Area step of Registration for non-popular areas
export const SimpleCard: React.FC<SimpleCardProps> = ({ text, focus = false, onClick }) => {
  return (
    <Box
      borderColor={focus ? 'information.400' : ''}
      borderBottomWidth="2px"
      overflow="hidden"
      alignContent="left"
      p="4"
      cursor="pointer"
      onClick={onClick}
    >
      <Box fontWeight="medium" fontSize="md">
        {text}
      </Box>
    </Box>
  );
};
