import { Box, Flex } from '@lego/klik-ui';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from './auth/auth-provider';
import { Fonts } from './components/Fonts';
import { LoadingSpinner } from './components/LoadingSpinner';
import { RequireAuth } from './components/RequireAuth';
import { AdminPage } from './pages/AdminPage';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { NoMatch } from './pages/NoMatch';
import { RegistrationFailedPage } from './pages/RegistrationFailedPage';
import { RegistrationPage, RegistrationType } from './pages/RegistrationPage';
import { RegistrationSuccessPage } from './pages/RegistrationSuccessPage';

export const App: React.FC = () => {
  const auth = useAuth();

  // Untill auth is loaded we will show loading spinner
  if (!auth.hasLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <Flex direction="column" minH="100vh" overflowX="hidden">
      <Box as="main" display="flex" flex={1} flexDirection="column">
        <Routes>
          <Route
            element={
              <RequireAuth>
                <LandingPage />
              </RequireAuth>
            }
            path="/"
          />
          <Route
            element={
              <RequireAuth>
                <RegistrationPage registrationType={RegistrationType.BookingIn} />
              </RequireAuth>
            }
            path="/booking-in"
          />
          <Route
            element={
              <RequireAuth>
                <RegistrationPage registrationType={RegistrationType.ShipOut} />
              </RequireAuth>
            }
            path="/ship-out"
          />
          <Route
            element={
              <RequireAuth>
                <RegistrationSuccessPage />
              </RequireAuth>
            }
            path="/registration-complete"
          />
          <Route
            element={
              <RequireAuth>
                <RegistrationFailedPage />
              </RequireAuth>
            }
            path="/registration-failed"
          />
          <Route
            element={
              <RequireAuth>
                <AdminPage />
              </RequireAuth>
            }
            path="/admin"
          />
          <Route element={<LoginPage />} path="/login" />
          {/* 404 - Not found */}
          <Route element={<NoMatch />} path="*" />
        </Routes>
        <Fonts />
      </Box>
    </Flex>
  );
};
