import { Box, CircularProgress } from '@lego/klik-ui';
import React from 'react';

// Styled loading spinner
export const LoadingSpinner: React.FC = () => {
  return (
    <Box alignItems="center" display="flex" h="80vh" justifyContent="center" w="100%">
      <CircularProgress size="lg" isIndeterminate={true} />
    </Box>
  );
};
