import { AmplifyConfig } from './types/amplify';

// Extract for just url before # and ?
// Example from http://localhost:3000/events#fragment?queryParam=blah will be extracted http://localhost:3000/login
const redirect = `${window.location.origin}/login`;

export const amplifyConfig: AmplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION as string,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_HOSTED_DOMAIN,
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL ?? redirect,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL ?? redirect,
      responseType: 'code',
    },
  },
};
