import { AspectRatio, Box, Image } from '@lego/klik-ui';

interface ImageCardProps {
  text: string;
  image: string;
  focus?: boolean;
  onClick?: React.MouseEventHandler;
}

// Styled image card component used in Category, WasteType & Container step of Registration
export const ImageCard: React.FC<ImageCardProps> = ({ text, image, focus = false, onClick }) => {
  return (
    <Box
      borderWidth={focus ? '2px' : '1px'}
      borderColor={focus ? 'information.400' : ''}
      borderRadius="md"
      overflow="hidden"
      onClick={onClick}
      cursor="pointer"
    >
      <AspectRatio ratio={1}>
        <Image src={image} alt={text} />
      </AspectRatio>

      <Box fontWeight="medium" fontSize="md" p="4">
        {text}
      </Box>
    </Box>
  );
};
