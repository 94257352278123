import { mode } from '@chakra-ui/theme-tools';
import { Dict, Variant } from '../types';

const var100: Variant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    backgroundColor: mode(`${c}.100`, 'white')(props),
    boxShadow: 'button',
    color: mode('black', `${c}.500`)(props),
    _hover: {
      backgroundColor: mode(`${c}.200`, 'slate.50')(props),
    },
    _active: {
      boxShadow: 'none',
      backgroundColor: mode(`${c}.200`, 'slate.50')(props),
    },
  };
};

const var200: Variant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    backgroundColor: mode(`${c}.200`, 'white')(props),
    boxShadow: 'button',
    color: mode('black', `${c}.500`)(props),
    _hover: {
      backgroundColor: mode(`${c}.300`, 'slate.50')(props),
    },
    _active: {
      boxShadow: 'none',
      backgroundColor: mode(`${c}.300`, 'slate.50')(props),
    },
  };
};

const var300: Variant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    backgroundColor: mode(`${c}.300`, 'white')(props),
    boxShadow: 'button',
    color: mode('black', `${c}.500`)(props),
    _hover: {
      backgroundColor: mode(`${c}.400`, 'slate.50')(props),
    },
    _active: {
      boxShadow: 'none',
      backgroundColor: mode(`${c}.400`, 'slate.50')(props),
    },
  };
};

const var400: Variant = (props: Dict) => {
  const { colorScheme: c } = props;

  return {
    backgroundColor: mode(`${c}.400`, 'white')(props),
    boxShadow: 'button',
    color: mode('black', `${c}.500`)(props),
    _hover: {
      backgroundColor: mode(`${c}.500`, 'slate.50')(props),
    },
    _active: {
      boxShadow: 'none',
      backgroundColor: mode(`${c}.500`, 'slate.50')(props),
    },
  };
};

export const button = {
  variants: {
    var100: var100,
    var300: var300,
    var200: var200,
    var400: var400,
  },
};
