import { Stack } from "@chakra-ui/layout";
import { Box, Button, Flex, Menu, Spacer, Tooltip } from "@lego/klik-ui";
import { ArrowDownBold, Home, LogOut } from '@lego/klik-ui-icons';
import React from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from "../auth/auth-provider";
import { UserTable } from "../components/admin/UserTable";
import { default as i18n, default as i18next, languages } from '../utils/i18n';

export const AdminPage: React.FC = () => {
    const navigate = useNavigate();
    const { isAdmin, isSupervisor, logout } = useAuth();

    if (!isAdmin && !isSupervisor) {
        return <Navigate replace={true} to={"/"} />;
    }

    return (
        <Flex direction="column" height="100vh" width="100vw">
            <Flex flex="1" bg="primary" align="center" p="2">
                <Spacer />
                <Box mr={5}>
                    <Menu>
                        <Menu.Button
                            as={Button}
                            rightIcon={<ArrowDownBold />}
                            background="transparent"
                            borderWidth="2px"
                            borderColor="white"
                            _hover={{ background: 'transparent' }}
                            _active={{ background: 'transparent' }}
                        >
                            {i18next.language}
                        </Menu.Button>
                        <Menu.List border={0} borderRadius={0} background="#E8F2FA">
                            {languages.map((lang) => (
                                <Menu.Item
                                    key={lang.code}
                                    isDisabled={lang.code === i18next.language}
                                    onClick={() => void i18n.changeLanguage(lang.code)}
                                >
                                    {lang.code} - {lang.name}
                                </Menu.Item>
                            ))}
                        </Menu.List>
                    </Menu>
                </Box>
                <Box mr={5}>
                    <Button onClick={logout}>
                        <LogOut />
                    </Button>
                </Box>
                <Box >
                    <Tooltip label="Go to home">
                        <Button onClick={() => navigate("/")}>
                            <Home />
                        </Button>
                    </Tooltip>
                </Box>
            </Flex>
            <Stack flex="10" overflow="auto" w="100vw" m="10">
                <UserTable />
            </Stack>
        </Flex>
    )
}