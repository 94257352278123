import { Box, Flex, Image } from '@lego/klik-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '../utils/assets/error.svg';

// Page indicating registration failed and user should start over
export const RegistrationFailedPage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Flex direction="column" h="100vh">
      <Flex flex="1" bg="failed"></Flex>
      <Flex flex="8" align="center" justify="center" direction="column">
        <Box
          mb={{
            md: 12,
            sm: 8,
            base: 4,
          }}
          fontWeight="bold"
          fontSize={{
            md: '6xl',
            sm: '4xl',
            base: '2xl',
          }}
          color="error.600"
        >
          {t('regi_error.unknown')}
        </Box>
        <Flex
          align="center"
          justify="center"
          direction="column"
          cursor="pointer"
          onClick={() => {
            navigate('/', { replace: true });
          }}
        >
          <Image ignoreFallback={true} src={ErrorIcon} />
          <Box m="4" fontSize="xl" fontWeight="medium" textAlign="center">
            {t('regi_error.start_over')}
          </Box>
          <Box textAlign="center">{t('regi_error.ask_for_data_refill')}</Box>
        </Flex>
      </Flex>
      <Flex flex="3" bg="failed" align="center" justify="left">
        <Box /*align="left"*/ ml="8" color="white">
          {<Box>{t('shared.get_help')}</Box>}
        </Box>
      </Flex>
    </Flex>
  );
};
