import { WasteType } from '@digi-waste/generated-models';
import { Grid, IStackProps, Stack } from '@lego/klik-ui';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { hazardousKey, nonHazardousKey } from '../../../utils/consts';
import { IRegistrationForm } from '../../../utils/types/custom';
import { ImageCard } from '../components/ImageCard';

interface IGenericStepProps extends IStackProps {
  goToNextStep: () => void;
  wasteTypes?: WasteType[];
}

// Category step used for filtration of waste type based on if they are hazardous or not
// For areas that do not have multiple categories, the waste type step is skipped
export const CategoryStep: React.FC<IGenericStepProps> = ({
  goToNextStep,
  wasteTypes = [],
  ...stackProps
}) => {
  const { getValues, setValue } = useFormContext<IRegistrationForm>();
  const { t } = useTranslation();

  // When category card is clicked, set the categoryId to the value of the card and move to the next step
  const onClick = useCallback(
    (value: string) => {
      setValue('categoryName', value);
      goToNextStep();
    },
    [setValue, goToNextStep]
  );

  const selected = getValues('categoryName');
  const isAreaSelected = !!getValues('areaId');

  let hazardous = 0,
    nonHazardous = 0;

  // Count hazardous and non hazrdous between common waste types
  wasteTypes
    .filter((x) => x.isCommon)
    .forEach((x) => (x.isHazardous ? hazardous++ : nonHazardous++));

  // We do not have selected category yet
  // If there is only one category, skip to the next step
  if (isAreaSelected && (!hazardous || !nonHazardous) && !selected && stackProps.hidden === false) {
    setValue('categoryName', hazardous ? hazardousKey : nonHazardousKey);
    goToNextStep();
  }

  return (
    <Stack
      spacing={6}
      {...stackProps}
      padding={10}
      width="100vw"
      justifyItems="center"
      alignItems="center"
    >
      <Grid
        gap="6"
        templateColumns={{
          md: `repeat(2, 1fr)`,
          base: 'repeat(1, 1fr)',
        }}
        width="60vw"
      >
        <ImageCard
          text={t(`shared.${nonHazardousKey}`)}
          image={'./images/category/Non-hazardous.png'}
          focus={selected === nonHazardousKey}
          onClick={() => onClick(nonHazardousKey)}
        />
        <ImageCard
          text={t(`shared.${hazardousKey}`)}
          image={'./images/category/Hazardous.png'}
          focus={selected === hazardousKey}
          onClick={() => onClick(hazardousKey)}
        />
      </Grid>
    </Stack>
  );
};
