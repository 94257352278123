import { Global } from '@emotion/react';
import React from 'react';

// Fonts used by The LEGO Group
export const Fonts: React.FC = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 300;
        src: url('/fonts/CeraPro-Light.eot');
        src: local('Cera Pro Light'), local('CeraPro-Light'),
          url('/fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-Light.woff2') format('woff2'), url('/fonts/CeraPro-Light.woff') format('woff'),
          url('/fonts/CeraPro-Light.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: italic;
        font-weight: 500;
        src: url('/fonts/CeraPro-MediumItalic.eot');
        src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
          url('/fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-MediumItalic.woff2') format('woff2'),
          url('/fonts/CeraPro-MediumItalic.woff') format('woff'),
          url('/fonts/CeraPro-MediumItalic.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: italic;
        font-weight: normal;
        src: url('/fonts/CeraPro-RegularItalic.eot');
        src: local('Cera Pro Regular Italic'), local('CeraPro-RegularItalic'),
          url('/fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-RegularItalic.woff2') format('woff2'),
          url('/fonts/CeraPro-RegularItalic.woff') format('woff'),
          url('/fonts/CeraPro-RegularItalic.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: italic;
        font-weight: 900;
        src: url('/fonts/CeraPro-BlackItalic.eot');
        src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
          url('/fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-BlackItalic.woff2') format('woff2'),
          url('/fonts/CeraPro-BlackItalic.woff') format('woff'),
          url('/fonts/CeraPro-BlackItalic.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/CeraPro-Medium.eot');
        src: local('Cera Pro Medium'), local('CeraPro-Medium'),
          url('/fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-Medium.woff2') format('woff2'), url('/fonts/CeraPro-Medium.woff') format('woff'),
          url('/fonts/CeraPro-Medium.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: italic;
        font-weight: bold;
        src: url('/fonts/CeraPro-BoldItalic.eot');
        src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
          url('/fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-BoldItalic.woff2') format('woff2'), url('/fonts/CeraPro-BoldItalic.woff') format('woff'),
          url('/fonts/CeraPro-BoldItalic.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: italic;
        font-weight: 100;
        src: url('/fonts/CeraPro-ThinItalic.eot');
        src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
          url('/fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-ThinItalic.woff2') format('woff2'), url('/fonts/CeraPro-ThinItalic.woff') format('woff'),
          url('/fonts/CeraPro-ThinItalic.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: italic;
        font-weight: 300;
        src: url('/fonts/CeraPro-LightItalic.eot');
        src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
          url('/fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-LightItalic.woff2') format('woff2'),
          url('/fonts/CeraPro-LightItalic.woff') format('woff'),
          url('/fonts/CeraPro-LightItalic.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 900;
        src: url('/fonts/CeraPro-Black.eot');
        src: local('Cera Pro Black'), local('CeraPro-Black'),
          url('/fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-Black.woff2') format('woff2'), url('/fonts/CeraPro-Black.woff') format('woff'),
          url('/fonts/CeraPro-Black.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: bold;
        src: url('/fonts/CeraPro-Bold.eot');
        src: local('Cera Pro Bold'), local('CeraPro-Bold'),
          url('/fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-Bold.woff2') format('woff2'), url('/fonts/CeraPro-Bold.woff') format('woff'),
          url('/fonts/CeraPro-Bold.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: normal;
        src: url('/fonts/CeraPro-Regular.eot');
        src: local('Cera Pro Regular'), local('CeraPro-Regular'),
          url('/fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-Regular.woff2') format('woff2'), url('/fonts/CeraPro-Regular.woff') format('woff'),
          url('/fonts/CeraPro-Regular.ttf') format('truetype');
      }

      @font-face {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 100;
        src: url('/fonts/CeraPro-Thin.eot');
        src: local('Cera Pro Thin'), local('CeraPro-Thin'),
          url('/fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
          url('/fonts/CeraPro-Thin.woff2') format('woff2'), url('/fonts/CeraPro-Thin.woff') format('woff'),
          url('/fonts/CeraPro-Thin.ttf') format('truetype');
      }
      `}
  />
);
