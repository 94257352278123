import { extendTheme } from '@lego/klik-ui';
import { button } from './components/button';
import { stepper } from './components/stepper';

export const theme = extendTheme({
  colors: {
    primary: 'linear-gradient(180deg, #0C3987 0%, #1951B2 100%)',
    failed: 'linear-gradient(180deg, #8C164D 0%, #B22567 100%)',
  },
  components: {
    Button: button,
    Stepper: stepper,
  },
});
