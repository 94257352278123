import { Button, Flex, Image, Spacer, Stack, Text } from '@lego/klik-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Headline } from '../components/Typography/Headline';
import womanMagnifyingGlass from '../utils/assets/woman-magnifying-glass.svg';

interface INoMatchProps {
  redirectUrl?: string;
}

// Page show when no route matching is found
export const NoMatch: FC<INoMatchProps> = ({ redirectUrl = '/' }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      justifyContent="center"
      bg="primary"
      padding={20}
      width="100vw"
      height="100vh"
    >
      <Stack spacing={10}>
        <Headline color="#DDDDDD">{t('not_found_page.title')}</Headline>
        <Text color="#DDDDDD">{t('not_found_page.message')}</Text>

        <Button colorScheme="secondary" onClick={() => void navigate(redirectUrl)}>
          {t('not_found_page.button')}
        </Button>
        <Spacer />
        <Image h={400} ignoreFallback={true} left={5} src={womanMagnifyingGlass} top={160} />
      </Stack>
    </Flex>
  );
};
