import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const languages = [
  { code: 'en', name: 'English' },
  { code: 'hu', name: 'Magyarország' },
];

var initOptions: InitOptions = {
  fallbackLng: 'en',
  defaultNS: 'app',
  backend: {
    loadPath: 'locales/{{lng}}/{{ns}}.json',
  },
  supportedLngs: languages.map((x) => x.code),
  returnNull: false,
  debug: process.env.NODE_ENV === 'development',
};

i18next
  // i18next-http-backendy
  // loads translations from your server (in this case from local directory)
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // we will only use localStorage for saving lang
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(initOptions);

export default i18next;
