// Custom error class including status code and error code
export class FetchError extends Error {
  public constructor(public message: string, public statusCode: number, public errorCode?: string) {
    super(message);
  }
}

// Check if error is FetchError
export const isFetchError = (obj: unknown): obj is FetchError => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return 'statusCode' in (obj as any);
};
