import { Button } from '@lego/klik-ui';
import { ComponentProps, FC } from 'react';

// Styled button for calculator in Weight step of Registration
export const CalculatorButton: FC<ComponentProps<typeof Button>> = ({
  children,
  bg,
  disabled,
  fontWeight = 'normal',
  onClick,
  variant,
  colorScheme,
}) => {
  return (
    <Button
      disabled={disabled}
      bg={bg}
      variant={variant}
      colorScheme={colorScheme}
      fontSize={{
        md: '6xl',
        sm: '5xl',
        base: '4xl',
      }}
      fontWeight={fontWeight}
      w="100%"
      h="100%"
      py={{
        md: 6,
        sm: 4,
        base: 2,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
