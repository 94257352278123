import { Container } from '@digi-waste/generated-models';
import { Box, Grid, IStackProps, Stack, Text } from '@lego/klik-ui';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { request } from '../../../utils/api';
import { IRegistrationForm } from '../../../utils/types/custom';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ImageCard } from '../components/ImageCard';

interface IGenericStepProps extends IStackProps {
  goToNextStep: () => void;
}

// Container step showing all containers
export const ContainerStep: React.FC<IGenericStepProps> = ({ goToNextStep, ...stackProps }) => {
  const { t } = useTranslation();

  const { getValues, setValue } = useFormContext<IRegistrationForm>();

  const selected = getValues('containerId');

  const {
    data: containers,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['containers'],
    queryFn: async () => {
      return await request<Container[]>(`containers`);
    }
  });

  // When container card is clicked, set the containerId to the value of the card and move to the next step
  const onClick = useCallback(
    (container: Container) => {
      setValue('containerId', container.id);
      setValue('containerName', container.name);
      goToNextStep();
    },
    [setValue, goToNextStep]
  );

  if (isLoading) {
    return (
      <Stack spacing={6} {...stackProps} padding={10} width="100vw">
        <LoadingSpinner />
      </Stack>
    );
  }

  if (isError || !containers) {
    return (
      <Stack spacing={6} {...stackProps} padding={10} width="100vw">
        <Text>{t('regi_page.loading_containers_error')}</Text>
      </Stack>
    );
  }

  return (
    <Stack spacing={6} {...stackProps} padding={10} width="100vw">
      <Box fontWeight="bold" fontSize="xl">
        {t('regi_page.select_container')}
      </Box>
      <Grid
        gap="6"
        templateColumns={{
          lg: 'repeat(6, 1fr)',
          md: 'repeat(4, 1fr)',
          base: 'repeat(2, 1fr)',
        }}
        mt="2"
        pb="12"
      >
        {containers.map((container) => (
          <ImageCard
            key={container.id}
            text={t(`db:containers.${container.name}`)}
            image={'./images/container/' + container.image}
            focus={container.id === selected}
            onClick={() => onClick(container)}
          />
        ))}
      </Grid>
    </Stack>
  );
};
