import { ITextProps, Text } from '@lego/klik-ui';
import React from 'react';

// Styling of headline text
export const Headline: React.FC<ITextProps> = ({ children, ...textProps }) => {
  return (
    <Text
      as="h1"
      color="secondary.200"
      fontSize="3xl"
      fontWeight="medium"
      lineHeight="3xl"
      {...textProps}
    >
      {children}
    </Text>
  );
};
