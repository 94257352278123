import { Box, Image, Stack } from '@lego/klik-ui';

interface CardProps {
  text: string;
  subText: string;
  image?: string;
  bg?: string;
  focus?: boolean;
  onClick?: React.MouseEventHandler;
}

// Styled card for Area & WasteType step of Registration showing popular areas/waste types
export const Card: React.FC<CardProps> = ({
  text,
  subText,
  image = '',
  bg = '',
  focus = false,
  onClick,
}) => {
  return (
    <Box
      borderWidth={focus ? '2px' : '1px'}
      borderColor={focus ? 'information.400' : ''}
      borderRadius="md"
      overflow="hidden"
      alignContent="left"
      p="4"
      bg={bg}
      cursor="pointer"
      onClick={onClick}
    >
      <Stack
        direction={{
          sm: 'row',
          base: 'column',
        }}
        spacing="2"
      >
        {image && (
          <Image
            src={image}
            alt={text}
            borderRadius="md"
            width="12"
            height="12"
            objectFit="cover"
          />
        )}
        <Box>
          <Box fontWeight="medium" fontSize="md">
            {text}
          </Box>
          <Box mt="1" fontSize="sm">
            {subText}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
