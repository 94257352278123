/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Auth } from 'aws-amplify';
import { FetchError } from './FetchError';
import { Log } from './log';
import { ICognitoUser } from './types/amplify';

export const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:4114';

// Retrieves amplify token (internaly checks if cachec token is valid, if not it will refresh it)
const getAmplifyToken = async (): Promise<string> => {
  const user = (await Auth.currentAuthenticatedUser()) as ICognitoUser;

  return user.signInUserSession.accessToken.jwtToken;
};

export const request = async <T>(path: string, config?: RequestInit): Promise<T> => {
  try {
    const token = await getAmplifyToken();

    // Add needed headers
    const defaultHeaders = {
      // We are sending jsons
      'Content-Type': 'application/json',
      // Authroziation token we got from amplify
      Authorization: `Bearer ${token}`,
    };

    const defaultConfig: RequestInit = {
      headers: defaultHeaders,
      ...config,
    };

    const response = await fetch(`${API_URL}/${path}`, defaultConfig);

    // We got response from server
    if (response.ok) {
      // Response is not empty
      if (response.status !== 204) {
        const result = (await response.json()) as T;

        Log.info(path, result);

        return result;
      }

      // We assume consumer has typed response as undefined in this case
      return undefined as unknown as T;
    } else {
      // We got error from server, we will try to get error body
      const errorResult = (await response.json()) as { message: string; errorCode?: string };
      Log.error(errorResult);

      throw new FetchError(errorResult.message, response.status, errorResult.errorCode);
    }
  } catch (e: unknown) {
    throw e;
  }
};
